import { Component, OnInit, ViewChild } from '@angular/core';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { LoginService } from '../../../services/login/login.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FacturasMasivasDialogComponent } from '../../dialogs/facturas-masivas-dialog/facturas-masivas-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { Contrato } from '../../../interfaces/contrato';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),

  ]
})
export class ContratosComponent implements OnInit {
  contratos: Contrato[];
  idCliente: any;
  mostrarSpinner = true;
  textoModal: string;
  visibleFacturas: boolean;
  monopunto: boolean;
  isLoading = true;
  tokenvalidado: any;
  tipocliente: any;
  MostrarDescargaMasivaFacturasPorCups: any;
  MostrarAdministradorListadoContratoFechaAlta: any;
  MostrarAdministradorListadoContratoFechaVto: any;
  MostrarAdministradorListadoContratoFechaBaja: any;
  MostrarAdministradorListadoContratoEntorno: any;
  MostrarListadoContratoDireccionSuministro: any;
  MostrarListadoContratoDenominacionCliente: any;
  MostrarListadoContratoBotonGraficos: any;
  IsAdministrador: any;

  dialogRef: any;
  agrupacontratoscups = environment.agrupacontratoscups;
  MostrarDeuda = environment.MostrarDeuda;
  PrefijoNumeracionContratos = environment.PrefijoNumeracionContratos;
  EleiaNombreAdministradorFincas = environment.EleiaNombreAdministradorFincas;
  DesarrolloCatgas = environment.DesarrolloCatgas;

  ApiCallContratosListaElekluz: any;
  SpinnerDescargaContrato: boolean = false;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource<Contrato>();
  selection = new SelectionModel<Contrato>(true, []);

  EntornoFilter = new FormControl();
  NumFincaFilter = new FormControl();
  ContratoFilter = new FormControl();
  SituacionFilter = new FormControl();
  IdentidadFilter = new FormControl();
  NombreFilter = new FormControl();
  DireccionSuministroFilter = new FormControl();
  DenominacionClienteFilter = new FormControl();
  CupsFilter = new FormControl();
  FechaAltaFilter = new FormControl();
  FechaVencimientoFilter = new FormControl();
  FechaBajaFilter = new FormControl();
  TarifaFilter = new FormControl();
  DeudaFilter = new FormControl();

  filteredValues = {
    Entorno: '',
    NumFinca: '',
    Contrato: '',
    Situacion: '',
    Identidad: '',
    Nombre: '',
    DireccionSuministro: '',
    DenominacionCliente: '',
    Cups: '',
    FechaAlta: '',
    FechaVencimiento: '',
    FechaBaja: '',
    Tarifa: '',
    Deuda: ''
  };

  expandedElement: any;
  CambiarTitularElekluz: boolean;
  nuevoContratoEntorno: String = window.location.href.includes('clientes.wekiwi.es') ? 'https://alta.wekiwi.es/' : 'https://fawekiwitest.sigeenergia.com/'

  constructor(
    private router: Router,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    private almnService:AlmacenamientoService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        console.log(respuesta);
        refToThis.cargarTablaContratos();
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);

    this.ApiCallContratosListaElekluz = environment.ApiCallContratosListaElekluz;

    this.MostrarAdministradorListadoContratoFechaAlta = environment.MostrarAdministradorListadoContratoFechaAlta;
    this.MostrarAdministradorListadoContratoFechaVto = environment.MostrarAdministradorListadoContratoFechaVto;
    this.MostrarAdministradorListadoContratoFechaBaja = environment.MostrarAdministradorListadoContratoFechaBaja;
    this.MostrarAdministradorListadoContratoEntorno = environment.MostrarAdministradorListadoContratoEntorno;
    this.MostrarListadoContratoDireccionSuministro = environment.MostrarListadoContratoDireccionSuministro;
    this.MostrarListadoContratoDenominacionCliente = environment.MostrarListadoContratoDenominacionCliente;
    this.EleiaNombreAdministradorFincas = environment.EleiaNombreAdministradorFincas;


    this.MostrarListadoContratoBotonGraficos = environment.MostrarListadoContratoBotonGraficos;

    this.IsAdministrador = this.almnService.getValue('isAdminFincas');

    this.CambiarTitularElekluz = environment.CambiarTitularElekluz;

    this.tipocliente = this.almnService.getValue('TipoCliente');

    this.displayedColumns.push('CodigoContrato');
    this.displayedColumns.push('ContratoSituacion');
    this.displayedColumns.push('Tarifa');
    this.displayedColumns.push('Entorno');
    // this.displayedColumns.push('Facturas'); //WEB-7718
    this.displayedColumns.push('Dropdown');
  }

  cargarDescuentos(contrato) {
    const numPeriodicidad = contrato.IsPrecarga ? 'MENSUAL' == (contrato.Periodicidad || "").toUpperCase() ? 1 : 'BIMENSUAL' == (contrato.Periodicidad || "").toUpperCase() ? 2 : 3 : 0;

    this._contratosService.getDescuentosTarifaGrupo(contrato.IdTarifaGrupo, numPeriodicidad).subscribe(
      async data => {
        var descuentos = this._funcionesService.decodificarToken(data);
        descuentos = this._contratosService.recalcularDescuentos(descuentos);
        if(descuentos.length != 0) {
          descuentos.forEach(element => {
            switch (element.TipoCargoDescuento) {
              case '1':
                contrato['DescuentoTarifa'] = Math.abs(element.CargoDescuentoEnergia);
                break;
              case '2':
                contrato['DescuentoOnline'] = Math.abs(element.CargoDescuentoEnergia);
                break;
              case '3':
                contrato['DescuentoAcierta'] = Math.abs(element.CargoDescuentoEnergia);
                break;
            }
          });
        }
        // console.log(contrato);
        // console.log(descuentos);
    }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'tus descuentos');
    })
  }

  comprobarDatosContratos() {
    console.log('CONTRATOS CONTROLLER');

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.cargarTablaContratos();
    } else {
      this.cargarListadoContratos();
    }      
  }

  cargarTablaContratos() {
    this.isLoading = false;
    this.contratos = this._funcionesService.decodificarToken(JSON.parse(this.almnService.getValue("listadoContratos")));
    // console.log(this.contratos);
    this.monopunto = (this.contratos.length === 1) ? false : false;
    this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
    if(this.almnService.getValue('mostrarContrato') == 'true') {
      this.expandedElement = this.dataSource.filteredData.filter(function(x){return x.IdContrato == JSON.parse(atob(this.almnService.getValue("ContratoVisible"))).IdContrato},this)[0];
      this.almnService.setValue('mostrarContrato', 'false');
    }

    this.loaddata();
    this.customFilter();
    this.mostrarSpinner = false;
  };

  cargarListadoContratos() {
    this._contratosService
      .getListadoContratos(this.almnService.getValue('idCliente'))
      .subscribe(async data => {
        // console.log('CONTRATOOOOSSS',JSON.stringify(data));
        this.almnService.setValue("listadoContratos", JSON.stringify(data));
        this.cargarTablaContratos();
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  loaddata() {
    // console.log(this.dataSource);
    document.getElementsByClassName('adopta_arbol')[0];
    // if(this.dataSource.data.) {

    // }
  }

  verFacturasContrato(contrato) {
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(contrato)));
    this.router.navigate(['/informacion/facturas']);
  }

  descargaMasivaFacturasDialog() {

    this.dialogRef = this.dialog.open(FacturasMasivasDialogComponent, {
      data: {
        'IsAdministrador': this.almnService.getValue('isAdminFincas'),
        'IdCliente': this.almnService.getValue('idCliente'),
        'Entorno': this.almnService.getValue('entorno'),
        'ListadoContratos': this.dataSource.data
      }
    });


    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }


  customFilter() {
    this.EntornoFilter.valueChanges.subscribe(EntornoFilterValue => {
      this.filteredValues['Entorno'] = EntornoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NumFincaFilter.valueChanges.subscribe(NumFincaFilterValue => {
      this.filteredValues['NumFinca'] = NumFincaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.ContratoFilter.valueChanges.subscribe(ContratoFilterValue => {
      this.filteredValues['Contrato'] = ContratoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.SituacionFilter.valueChanges.subscribe(SituacionFilterValue => {
      this.filteredValues['Situacion'] = SituacionFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.IdentidadFilter.valueChanges.subscribe(IdentidadFilterValue => {
      this.filteredValues['Identidad'] = IdentidadFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NombreFilter.valueChanges.subscribe(NombreFilterValue => {
      this.filteredValues['Nombre'] = NombreFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DireccionSuministroFilter.valueChanges.subscribe(DireccionSuministroFilterValue => {
      this.filteredValues['DireccionSuministro'] = DireccionSuministroFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DenominacionClienteFilter.valueChanges.subscribe(DenominacionClienteFilterValue => {
      this.filteredValues['DenominacionCliente'] = DenominacionClienteFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.CupsFilter.valueChanges.subscribe(CupsFilterValue => {
      this.filteredValues['Cups'] = CupsFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaAltaFilter.valueChanges.subscribe(FechaAltaFilterValue => {
      this.filteredValues['FechaAlta'] = FechaAltaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaVencimientoFilter.valueChanges.subscribe(FechaVencimientoFilterValue => {
      this.filteredValues['FechaVencimiento'] = FechaVencimientoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaBajaFilter.valueChanges.subscribe(FechaBajaFilterValue => {
      this.filteredValues['FechaBaja'] = FechaBajaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.TarifaFilter.valueChanges.subscribe(TarifaFilterValue => {
      this.filteredValues['Tarifa'] = TarifaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DeudaFilter.valueChanges.subscribe(DeudaFilterValue => {
      this.filteredValues['Deuda'] = DeudaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: Contrato, filter: string): boolean {

      const searchString = JSON.parse(filter);
      if (data.DenominacionCliente == null) {
        data.DenominacionCliente = '';
      }
      if (data.FechaAlta == null) {
        data.FechaAlta = '';
      }
      if (data.FechaBaja == null) {
        data.FechaBaja = '';
      }
      if (data.FechaVto == null) {
        data.FechaVto = '';
      }
      if (data.NombreCliente == null) {
        data.NombreCliente = '';
      }
      if (data.NumFinca == null) {
        data.NumFinca = '';
      }
      if (data.CodigoContrato == null) {
        data.CodigoContrato = '';
      }
      if (data.ContratoSituacion == null) {
        data.ContratoSituacion = '';
      }
      if (data.Identidad == null) {
        data.Identidad = '';
      }
      if (data.NombreCliente == null) {
        data.NombreCliente = '';
      }
      if (data.DireccionSuministro == null) {
        data.DireccionSuministro = '';
      }
      if (data.Cups == null) {
        data.Cups = '';
      }
      if (data.Tarifa == null) {
        data.Tarifa = '';
      }
      if (data.PoblacionCUPS == null) {
        data.PoblacionCUPS = '';
      }
      if (data.DirecionFact == null) {
        data.DirecionFact = '';
      }
      return (
        data.Entorno.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Entorno.toLowerCase()) !== -1 &&
        data.NumFinca.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.NumFinca.toLowerCase()) !== -1 &&
        data.CodigoContrato.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Contrato.toLowerCase()) !== -1 &&
        data.ContratoSituacion.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Situacion.toLowerCase()) !== -1 &&
        data.Identidad.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Identidad.toLowerCase()) !== -1 &&
        data.NombreCliente.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Nombre.toLowerCase()) !== -1 &&
        data.DireccionSuministro.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DireccionSuministro.toLowerCase()) !== -1 &&
        data.DenominacionCliente.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DenominacionCliente.toLowerCase()) !== -1 &&
        data.Cups.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Cups.toLowerCase()) !== -1 &&
        data.FechaAlta.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaAlta.toLowerCase()) !== -1 &&
        data.FechaVto.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaVencimiento.toLowerCase()) !== -1 &&
        data.FechaBaja.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaBaja.toLowerCase()) !== -1 &&
        data.Tarifa.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Tarifa.toLowerCase()) !== -1
      );
    };
    return myFilterPredicate;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  descargarContrato(contrato) {
    this.SpinnerDescargaContrato = true;
    this._contratosService.getDescargaContratoPDF(contrato).subscribe(
      data => {
        this.SpinnerDescargaContrato = false;
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'descarga contrato');
      });
  }
}
