import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { environment } from 'src/environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';


@Injectable({
  providedIn: 'root'
})

export class MailService {

  token: string;
  urlApi: string;
  bodyApiToken: any;
  _URL_: string;
  urlPhp:string
  destinatario: string;


  constructor(
    private http: HttpClient,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService) {
    this._URL_ = environment._URL_;
    this.urlPhp = environment.urlPhpControllers;
    this.urlApi = this._globalService.getUrlApi();
    this.token = this.almnService.getValue('token');
	this.destinatario = environment.email;
  }

  getEnvioEmail(cuerpo): Observable<any> {

    // Para el response en json 
    // const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'text'};

    // Para response en texto plano
    const options: Object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };

	
	var Asunto=cuerpo.Asunto;
	var mensaje=cuerpo.Cuerpo;
	var ReplyTo=cuerpo.ReplyTo;

	const body ={
		"To": [this.destinatario],
		"Subject": Asunto,
		"Body": mensaje,
		"IsBodyHTml": true,
		"TipoComunicado":"SO"
  };

	return this.http.post<any>(this.urlApi + 'SMTP/Send' ,  body,  this._globalService.getHttpHeaders());
    // return this.http.post<any>( this._URL_ + '/libs/envio.php', body, options);
    // return this.http.post<any>('http://192.168.2.17/desarrollo/test/envio.php', body, this._globalService.getHttpHeaders());
  }

  sendMail(destinatario, obj, interno): Observable<any> {
    const data = {
      email: destinatario,   
      Identidad: obj.Identidad,
      nombre: this.almnService.getValue('nombreCliente'),
      codContrato: obj.codContrato,
      codCups: obj.codCups,
      mensaje: obj.mensaje,
      interno: interno,
      ventana: obj.ventana
    }

      var cambios = data.mensaje;
      var nombre = data.nombre;
      var codContrato = data.codContrato;
      var codCups = data.codCups;
      var Identidad = data.Identidad;
      var interno = data.interno;
      var contenido = "";
      var contenidoTabla = "";
      var Asunto = Identidad+'_'+codContrato+'_'+codCups;
	

      cambios.forEach(cambio => {
        if (!cambio.includes('<table')) {
          contenido += `<li>${cambio}</li>`;
        } else {
          contenidoTabla += cambio;
        }
      });
    
    
      var tipoMensaje = `
      <p>Cliente: <b>${nombre}</b></p>
      <p>NIF: <b>${Identidad}</b></p>
      <p>Contrato: <b>${codContrato}</b></p>
      <p>CUPS: <b>${codCups}</b></p>
      <p>Modificaciones solicitadas:</p>
      <ul>${contenido}</ul>
      ${contenidoTabla}
      <hr>
      <p>Cualquier cosa que necesites no dudes en contactar con nosotros en el <a href="tel:900525748">900 52 57 48</a> o escríbenos a <a href="mailto:info@wekiwi.es">info@wekiwi.es</a></p>
    `;
      /* CUERPO DEL EMAIL */
    
      const mensaje = `
    <!doctype html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Notificación</title>
        	<style>
		/* -------------------------------------
			GLOBAL RESETS
		------------------------------------- */
		
		/*All the styling goes here*/
		
		img {
			border: none;
			-ms-interpolation-mode: bicubic;
			max-width: 100%; 
		}

		body {
			background-color: #f6f6f6;
			font-family: sans-serif;
			-webkit-font-smoothing: antialiased;
			font-size: 14px;
			line-height: 1.4;
			margin: 0;
			padding: 0;
			-ms-text-size-adjust: 100%;
			-webkit-text-size-adjust: 100%; 
		}


		.tablaRecargas {
			width:100%;
			border-collapse: collapse;
		}

		.tablaRecargas td,th {
			padding: 4px;
			white-space: nowrap;
			border: 1px solid whitesmoke;
		}
		.tablaRecargas th {
			border-bottom: 1px solid #b6b6b6;
			background-color: whitesmoke;
		}

		table {
			border-collapse: separate;
			mso-table-lspace: 0pt;
			mso-table-rspace: 0pt;
			width: 100%; 
		}
		table td {
			font-family: sans-serif;
			font-size: 14px;
			vertical-align: top; 
		}

		/* -------------------------------------
			BODY & CONTAINER
		------------------------------------- */

		.body {
			background-color: #f6f6f6;
			width: 100%; 
		}

		/* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
		.container {
			display: block;
			margin: 0 auto !important;
			/* makes it centered */
			max-width: 580px;
			padding: 10px;
			width: 580px; 
		}

		/* This should also be a block element, so that it will fill 100% of the .container */
		.content {
			box-sizing: border-box;
			display: block;
			margin: 0 auto;
			max-width: 580px;
			padding: 10px; 
		}

		/* -------------------------------------
			HEADER, FOOTER, MAIN
		------------------------------------- */
		.main {
			background: #ffffff;
			border-radius: 3px;
			width: 100%; 
		}

		.wrapper {
			box-sizing: border-box;
			padding: 20px; 
		}

		.content-block {
			padding-bottom: 10px;
			padding-top: 10px;
		}

		.footer {
			clear: both;
			margin-top: 10px;
			text-align: center;
			width: 100%; 
		}
			.footer td,
			.footer p,
			.footer span,
			.footer a {
			color: #999999;
			font-size: 12px;
			text-align: center; 
		}

		/* -------------------------------------
			TYPOGRAPHY
		------------------------------------- */
		h1,
		h2,
		h3,
		h4 {
			color: #000000;
			font-family: sans-serif;
			font-weight: 400;
			line-height: 1.4;
			margin: 0;
			margin-bottom: 30px; 
		}

		h1 {
			font-size: 35px;
			font-weight: 300;
			text-align: center;
			text-transform: capitalize; 
		}

		p,
		ul,
		ol {
			font-family: sans-serif;
			font-size: 14px;
			font-weight: normal;
			margin: 0;
			margin-bottom: 15px; 
		}
			p li,
			ul li,
			ol li {
			margin-left: 5px; 
		}

		a {
			color: #262478;
			text-decoration: underline; 
		}

		/* -------------------------------------
			BUTTONS
		------------------------------------- */
		.btn {
			box-sizing: border-box;
			width: 100%; }
			.btn > tbody > tr > td {
			padding-bottom: 15px; }
			.btn table {
			width: auto; 
		}
			.btn table td {
			background-color: #ffffff;
			border-radius: 5px;
			text-align: center; 
		}
			.btn a {
			background-color: #ffffff;
			border: solid 1px #262478;
			border-radius: 5px;
			box-sizing: border-box;
			color: #262478;
			cursor: pointer;
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			margin: 0;
			padding: 12px 25px;
			text-decoration: none;
			text-transform: capitalize; 
		}

		.btn-primary table td {
			background-color: #262478; 
		}

		.btn-primary a {
			background-color: #262478;
			border-color: #262478;
			color: #ffffff; 
		}

		/* -------------------------------------
			OTHER STYLES THAT MIGHT BE USEFUL
		------------------------------------- */
		.last {
			margin-bottom: 0; 
		}

		.first {
			margin-top: 0; 
		}

		.align-center {
			text-align: center; 
		}

		.align-right {
			text-align: right; 
		}

		.align-left {
			text-align: left; 
		}

		.clear {
			clear: both; 
		}

		.mt0 {
			margin-top: 0; 
		}

		.mb0 {
			margin-bottom: 0; 
		}

		.preheader {
			color: transparent;
			display: none;
			height: 0;
			max-height: 0;
			max-width: 0;
			opacity: 0;
			overflow: hidden;
			mso-hide: all;
			visibility: hidden;
			width: 0; 
		}

		.powered-by a {
			text-decoration: none; 
		}

		hr {
			border: 0;
			border-bottom: 1px solid #f6f6f6;
			margin: 20px 0; 
		}

		/* -------------------------------------
			RESPONSIVE AND MOBILE FRIENDLY STYLES
		------------------------------------- */
		@media only screen and (max-width: 620px) {
			table[class=body] h1 {
			font-size: 28px !important;
			margin-bottom: 10px !important; 
			}
			table[class=body] p,
			table[class=body] ul,
			table[class=body] ol,
			table[class=body] td,
			table[class=body] span,
			table[class=body] a {
			font-size: 16px !important; 
			}
			table[class=body] .wrapper,
			table[class=body] .article {
			padding: 10px !important; 
			}
			table[class=body] .content {
			padding: 0 !important; 
			}
			table[class=body] .container {
			padding: 0 !important;
			width: 100% !important; 
			}
			table[class=body] .main {
			border-left-width: 0 !important;
			border-radius: 0 !important;
			border-right-width: 0 !important; 
			}
			table[class=body] .btn table {
			width: 100% !important; 
			}
			table[class=body] .btn a {
			width: 100% !important; 
			}
			table[class=body] .img-responsive {
			height: auto !important;
			max-width: 100% !important;
			width: auto !important; 
			}
		}

		/* -------------------------------------
			PRESERVE THESE STYLES IN THE HEAD
		------------------------------------- */
		@media all {
			.ExternalClass {
			width: 100%; 
			}
			.ExternalClass,
			.ExternalClass p,
			.ExternalClass span,
			.ExternalClass font,
			.ExternalClass td,
			.ExternalClass div {
			line-height: 100%; 
			}
			.apple-link a {
			color: inherit !important;
			font-family: inherit !important;
			font-size: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
			text-decoration: none !important; 
			}
			#MessageViewBody a {
			color: inherit;
			text-decoration: none;
			font-size: inherit;
			font-family: inherit;
			font-weight: inherit;
			line-height: inherit;
			}
			.btn-primary table td:hover {
			background-color: #44438b !important; 
			}
			.btn-primary a:hover {
			background-color: #44438b !important;
			border-color: #44438b !important; 
			} 
		}

		</style>

      </head>
      <body>
        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <table role="presentation" class="main">
                  <tr>
                    <td class="wrapper">
                      <img src="https://fawekiwitest.sigeenergia.com/assets/img/logo.png" width="150"><br /><br /><br /><br />
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>${tipoMensaje}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
    </html>
  `;
    
  const body ={
	"To": [this.destinatario],
	"Subject": Asunto,
	"Body": mensaje,
	"IsBodyHTml": true,
	"TipoComunicado":"SO"
};
    
    return this.http.post<any>(this.urlApi + 'SMTP/Send' ,  body,  this._globalService.getHttpHeaders());
    // var url = window.location.href.includes('localhost') ? 'http://93.92.233.239/wekiwi/ovtest/libs/mailController.php' : 'libs/mailController.php';
    // var url ='http://93.92.233.239/wekiwi/ovtest/libs/mailController.php';
    // return this.http.post(url, data, { responseType: 'text'}) 
  }
}


