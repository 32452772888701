import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { LoginDirectoComponent } from './components/logindirecto/logindirecto.component';
import { ResumenComponent } from './components/usuario/resumen/resumen.component';
import { FacturasComponent } from './components/informacion/facturas/facturas.component';
import { ContratosComponent } from './components/informacion/contratos/contratos.component';
import { PerfilComponent } from './components/usuario/perfil/perfil.component';
import { CchComponent } from './components/cch/cch.component';
import { PasswordComponent } from './components/usuario/password/password.component';
import { RecordarContrasenaComponent } from './components/recordar-contrasena/recordar-contrasena.component';
import { SalirComponent } from './components/usuario/salir/salir.component';
import { PerfilContratoComponent } from './components/informacion/perfil-contrato/perfil-contrato.component';
import { PerfilCupsComponent } from './components/informacion/perfil-cups/perfil-cups.component';
import { Error404Component } from './components/errores/error404/error404.component';
import { PerfilIdentidadComponent } from './components/informacion/perfil-identidad/perfil-identidad.component';
import { GraficosComponent } from './components/informacion/graficos/graficos.component';
import { CambiosComponent } from './components/usuario/cambios/cambios.component';
import { WalletComponent } from './components/usuario/wallet/wallet.component';
//import { AutolecturaComponent } from './components/usuario/autolectura/autolectura.component';
import { HistoricoLecturaComponent } from './components/usuario/historico-lectura/historico-lectura.component';
import { HistoricoComponent } from './components/usuario/historico/historico.component';
// import { PrevisionComponent } from './components/usuario/prevision/prevision.component';
import { CadenaComponent } from './components/usuario/cadena/cadena.component';
import { ImpactoComponent } from './components/usuario/impacto/impacto.component';
import { CalculadoraComponent } from './components/usuario/calculadora/calculadora.component';

const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'logindirecto/user/:user/pass/:pass', component: LoginDirectoComponent },
  { path: 'resumen', component: ResumenComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'informacion/facturas', component: FacturasComponent },
  { path: 'informacion/contratos', component: ContratosComponent },
  { path: 'informacion/graficos/:idcups', component: GraficosComponent },
  { path: 'informacion/perfil/contrato/:idcontrato', component: PerfilContratoComponent },
  { path: 'informacion/perfil/cups/:idcups', component: PerfilCupsComponent },
  { path: 'informacion/perfil/cliente/:idcliente', component: PerfilIdentidadComponent },
  { path: 'informacion/facturas/:idcontrato', component: FacturasComponent },
  { path: 'cch', component: CchComponent },
  { path: 'password', component: PasswordComponent },
  { path: 'transacciones/cambios', component: CambiosComponent },
  { path: 'wallet', component: WalletComponent },
  // { path: 'consumo/autolectura', component: AutolecturaComponent },
  { path: 'consumo/hisoricolectura', component: HistoricoLecturaComponent},
  { path: 'consumo/historico', component: HistoricoComponent },
  { path: 'consumo/cadena', component: CadenaComponent },
  { path: 'consumo/calculadora', component: CalculadoraComponent },
  // { path: 'consumo/prevision', component: PrevisionComponent },
  { path: 'consumo/impacto', component: ImpactoComponent },
  { path: 'recordarcontrasena', component: RecordarContrasenaComponent },
  { path: 'salir', component: SalirComponent },
  { path: '**', component: LoginComponent }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' });
